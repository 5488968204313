import React from 'react'

const App = () => {
  return (
    <>
      <Header/>
      <About/>
      <Service/>
    </>
  )
}

export default App


const Header = ()=>{
  return (
    <nav className="flex justify-center  bg-blue-500">
      <ul className="flex flex-row space-x-4 h-16 items-center text-white uppercase font-semibold">
        <li>
          <a>Home</a>
        </li>
        <li>
          <a>About</a>
        </li>
        <li>
          <a>Service</a>
        </li>
        <li>
          <a>Contact</a>
        </li>
      </ul>
    </nav>
  );
}

const About = () =>{
  return(
    <div className='flex justify-around '>
      <div>
        <p>paragraph</p>
      </div>
      <div>
        <p>Image</p>
      </div>
    </div>
  )
}

const Service = ()=>{
  return (
    <div className="grid grid-cols-3 gap-4">
      <div>
        <h1>Header</h1>
        <p>paragraph</p>
      </div>
      <div>
        <h1>Header</h1>
        <p>paragraph</p>
      </div>
      <div>
        <h1>Header</h1>
        <p>paragraph</p>
      </div>
    </div>
  );
}